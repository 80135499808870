<template>
  <div class="QuizView">
    <ProgressBar :progress="progress" />
    <div class="header">
      <div
        class="exitButton --global-clickable"
        @click="$router.push('../home')">
        Exit
      </div>
    </div>
    <component
      :is="currentPageConf.component"
      :key="pageIndex"
      ref="test"
      class="body"
      :page-index="pageIndex"
      @page-completed="handlePageCompleted" />
  </div>
</template>

<script>
import ProgressBar from '@/modules/games-shared/components/ProgressBar';
import PageMatchTest from '../components/PageMatchTest';
import PageSingleItemWrapper from '../components/PageSingleItemWrapper';

export default {
    debugComponent: 'systems-shared_QuizViewDebug',
    components: { ProgressBar, PageMatchTest, PageSingleItemWrapper },
    inject: ['config', 'module'],
    props: {
        groupIndex: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            pageIndex: 0
        };
    },
    computed: {
        itemGroup() {
            return this.config.itemGroups[this.groupIndex];
        },
        currentPageConf() {
            const page = this.module.state.quiz.pages[this.pageIndex];
            return {
                component: page.wrapper || 'PageSingleItemWrapper'
            };
        },
        progress() {
            return this.module.getters('quiz/progress');
        }
    },
    created() {
        this.module.dispatch('quiz/load', { config: this.config, groupIndex: this.groupIndex });
    },
    beforeDestroy() {
        // this.module.dispatch('quiz/unload')
    },
    methods: {
        handlePageCompleted() {
            if (this.pageIndex < this.module.state.quiz.pages.length - 1) {
                this.pageIndex++;
            } else {
                if (this.progress === 1) {
                    this.itemGroup.itemIds.forEach(itemId => {
                        this.module.dispatch('setLearnStatus', { itemId, value: 1 });
                    });
                }
                this.$router.push('../learn');
            }
        }
    }
};
</script>

<style scoped lang="scss">
.QuizView {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: flex-end;
    height: 3em;
    align-items: center;
    padding-right: 2em;
}
.exitButton {
    margin-left: 2em;
    color: rgba(white, 0.5);
}

.body {
    position: relative;
    flex: 1;
    // padding: 1em;
    display: flex;
    flex-direction: column;
    // align-items: center;
}
</style>
