<template>
  <div class="PageMatchTest">
    <div class="instruction">
      {{ $t('instruction') }}
    </div>

    <SourceTargetInteraction
      :target-ids="targetIds"
      :source-ids="sourceIds"
      :check-match="checkMatch"
      source-selector=".ShelfItemWithImage"
      target-selector=".TargetGrid-Item">
      <template
        slot="default"
        slot-scope="{ sourceItems, targetItems }">
        <div class="TargetGrid">
          <TargetItem
            v-for="targetItem of targetItems"
            :key="targetItem.id"
            class="TargetGrid-Item"
            :data-id="targetItem.id"
            :data="targetItemsMap[targetItem.id]"
            :other="sourceItemsMap[targetItem.id]"
            v-bind="targetItem" />
        </div>
        <div>
          <Shelf
            class="shelf"
            :items="sourceItems"
            side-padding="0.5em">
            <template
              slot="shelfItem"
              slot-scope="sourceItem">
              <ShelfItemWithImage
                :state="sourceItem"
                v-bind="sourceItemsMap[sourceItem.id]"
                :data-id="sourceItem.id" />
            </template>
          </Shelf>
        </div>
      </template>
      <template
        slot="dragItem"
        slot-scope="props">
        <ShelfItemWithImage
          class="dragItem"
          :state="{}"
          v-bind="sourceItemsMap[props.id]" />
      </template>
    </SourceTargetInteraction>
  </div>
</template>

<script>
import shuffle from 'lodash-es/shuffle';

import SourceTargetInteraction from '@/modules/games-shared/components/SourceTargetInteraction';
import Shelf from '@/modules/games-shared/components/Shelf';
import ShelfItemWithImage from '@/modules/games-shared/components/ShelfItemWithImage';

import TargetItem from '@/modules/quiz/components/MatchTargetItem';

const translations = {
    instruction: 'Drag items to matching boxes',
    instruction_no: 'Dra objektet over til en av boksene'
};

export default {
    translations,
    components: { TargetItem, ShelfItemWithImage, SourceTargetInteraction, Shelf },
    inject: ['config', 'module'],
    props: {
        pageIndex: {
            type: Number
        }
    },

    data() {
        return {
            done: false,
            matchCount: 0
        };
    },

    watch: {
        done() {
            this.doneTimeoutId = setTimeout(() => {
                this.$emit('page-completed');
            }, 1000);
        }
    },

    created() {
        const itemGroup = this.config.itemGroups[this.$route.params.groupIndex];

        this.sourceIds = shuffle(itemGroup.itemIds);
        this.targetIds = itemGroup.itemIds;

        const targetItemsMap = {};
        this.targetIds.forEach(id => {
            targetItemsMap[id] = {
                id: id,
                label: this.config.items[id].label,
                image: this.config.items[id].image || this.config.items[id].objectImage
            };
        });
        this.targetItemsMap = targetItemsMap;
        this.sourceItemsMap = targetItemsMap;
    },

    beforeDestroy() {
        clearTimeout(this.doneTimeoutId);
    },

    methods: {
        checkMatch(sourceId, targetId) {
            const match = sourceId === targetId;
            if (match) {
                this.module.dispatch('quiz/updateTest', { itemId: sourceId, testIndex: 0, data: { done: true } });
                this.matchCount++;
                if (this.matchCount === this.targetIds.length) {
                    this.done = true;
                }
            }
            return match;
        },
        cheat() {
            this.targetIds.forEach(targetId => {
                this.module.dispatch('quiz/updateTest', { itemId: targetId, testIndex: 0, data: { done: true } });
            });
            this.done = true;
        }
    }
};
</script>

<style scoped lang="scss">
.PageMatchTest {
    display: flex;
    flex-direction: column;
}

.body {
    position: relative;
    flex: 1;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.instruction {
    font-size: 140%;
}
.SourceTargetInteraction {
    margin-top: 2em;
    flex: 1;
}
.TargetGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start; // NOTE: needed when this box has flex: 1

    flex: 1;
    overflow-y: auto;
}

.TargetGrid-Item {
    margin: 0.5em;
    /deep/ {
        .placeholderLabel {
            font-size: 140%;
            color: rgba(white, 0.4);
        }
    }
}

.dragItem {
    transform: scale(1.3);
}

.shelf {
    align-self: stretch;
    margin-top: 1em;
}
</style>
