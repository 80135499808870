<template>
  <div class="PageSingleItemWrapper">
    <component
      :is="componentConf.component"
      :key="testIndex"
      ref="test"
      class="content"
      :item-id="itemId"
      :test-index="testIndex"
      @test-completed="handleTestCompleted" />
  </div>
</template>

<script>
export default {
    props: {
        pageIndex: Number
    },
    inject: ['module', 'config'],
    data() {
        return {
            testIndex: 0
        };
    },
    computed: {
        page() {
            return this.module.state.quiz.pages[this.pageIndex];
        },
        itemId() {
            return this.page.itemIds[0];
        },
        quizItem() {
            return this.module.state.quiz.items[this.itemId];
        },
        componentConf() {
            const test = this.quizItem.tests[this.testIndex];
            return {
                component: this.config.components[test.name]
            };
        }
        // itemGroup () {
        //   return this.config.itemGroups[this.$route.params.groupIndex]
        // }
    },
    methods: {
        handleTestCompleted() {
            if (this.testIndex < this.quizItem.tests.length - 1) {
                this.testIndex++;
            } else {
                this.$emit('page-completed');
            }
        },
        cheat() {
            if (this.$refs.test.cheat) {
                this.$refs.test.cheat();
            }
        }
    }
};
</script>

<style scoped lang="scss">
.PageSingleItemWrapper {
}
.content {
    height: 100%;
}
</style>
